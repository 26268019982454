// Initialize referral tracking
export function initReferral() {
  try {
    // First check URL
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('ref');
    
    if (ref) {
      // If found in URL, save it to both window and localStorage
      window.tolt_referral = ref;
      localStorage.setItem('tolt_referral', ref);
      console.log('[Referral] Initialized from URL:', ref);
    } else {
      // If not in URL, check localStorage
      const savedRef = localStorage.getItem('tolt_referral');
      if (savedRef) {
        window.tolt_referral = savedRef;
        console.log('[Referral] Initialized from storage:', savedRef);
      } else {
        console.log('[Referral] No referral ID found');
      }
    }
  } catch (err) {
    console.error('[Referral] Error initializing referral:', err);
  }
}

// Get the current referral ID
export function getReferralId() {
  return window.tolt_referral || localStorage.getItem('tolt_referral');
}
