import React, { useEffect, useCallback, useState, useRef } from 'react';
import SockJS from 'sockjs-client';
import { over } from 'stompjs';
import { jwtDecode } from 'jwt-decode';
import { v4 as uuid } from 'uuid';
import { useSnackbar } from 'notistack';
import { useAtom } from 'jotai';
import { tokenAtom, isTokenExpiredAtom } from '../atoms/tokenAtoms';
import { stompClientAtom } from '../atoms/webSocketAtom';
import { useQueryClient } from 'react-query';
import { isReschedulingAtom } from '../atoms/isReschedulingAtom';

import { useCalendarEvents } from '../contexts/CalendarEventsContext';
import { useCustomer } from '../providers/CustomerProvider';
import { useCurrentPicture } from '../contexts/CurrentPictureContext';
import { useNavigate } from 'react-router-dom';

const StompClientConnector = () => {
    const [sessionId] = React.useState(uuid());
    const { enqueueSnackbar } = useSnackbar();
    const [stompClient, setStompClient] = useAtom(stompClientAtom);
    const [token] = useAtom(tokenAtom);
    const [isTokenExpired] = useAtom(isTokenExpiredAtom);
    const isConnectingRef = useRef(false);

    const { refetchCalendarEvents } = useCalendarEvents();
    const { customer, refetchCustomer } = useCustomer();
    const { updateCurrentPicture } = useCurrentPicture();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const reconnectAttemptRef = useRef(0);
    const maxReconnectAttempts = 10;
    const baseReconnectDelay = 1000;
    const reconnectTimeoutRef = useRef(null);
    const stompClientRef = useRef(null);

    const [isRescheduling, setIsRescheduling] = useAtom(isReschedulingAtom);

    const onPictureReceived = useCallback(
        (message) => {
            try {
                const pictureData = JSON.parse(message.body);
                updateCurrentPicture(pictureData);
            } catch (error) {
                console.error('Error parsing picture message:', error);
            }
        },
        [updateCurrentPicture]
    );

    const onMessageReceived = useCallback(
        (payload) => {
            console.log('Message received:', payload);
            if (payload.body.startsWith('TASK_DELETED:')) {
                const taskId = payload.body.substring(payload.body.indexOf(':') + 1);
                enqueueSnackbar('Task Deleted', {
                    variant: 'success',
                    dense: true,
                });
                queryClient.invalidateQueries('tasks');
            } else if (payload.body.startsWith('TASK_UPDATED:') || payload.body.startsWith('TASK_CREATED:')) {
                const splitIndex = payload.body.indexOf(':');
                const actionType = payload.body.substring(0, splitIndex);
                let taskData = payload.body.substring(splitIndex + 1);

                try {
                    taskData = JSON.parse(taskData);
                    const formattedActionType = actionType
                        .split('_')
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                        .join(' ');

                    enqueueSnackbar(`${formattedActionType}: ${taskData.title}`, {
                        variant: 'success',
                        dense: true,
                    });
                    queryClient.invalidateQueries('tasks');
                } catch (e) {
                    console.error('Failed to parse task data:', e);
                }
            } else if (payload.body === 'RESCHEDULING') {
                setIsRescheduling(true);
                enqueueSnackbar('Rescheduling tasks..', { variant: 'info', dense: true });
            } else if (payload.body === 'RESCHEDULED') {
                setIsRescheduling(false);
                enqueueSnackbar('Rescheduled tasks.', { variant: 'success', dense: true });
                queryClient.invalidateQueries('tasks');
                queryClient.invalidateQueries('calendarEvents');
            } else if (
                payload.body === 'CUSTOMER_UPDATED' ||
                payload.body === 'CUSTOMER_UPDATED_SUBSCRIPTION_RECEIPT'
            ) {
                console.log('Received customer update message:', payload.body);
                enqueueSnackbar('Customer updated', { variant: 'success', dense: true });
                
                console.log('Refetching customer data...');
                refetchCustomer().then((result) => {
                    console.log('Customer refetch result:', {
                        success: !!result,
                        hasData: !!result?.data,
                        accounts: result?.data?.accounts?.map(acc => ({
                            id: acc.id,
                            type: acc.type,
                            error: acc.error,
                            dirty: acc.dirty,
                            hasToken: !!acc.accessToken
                        }))
                    });

                    console.log('Invalidating calendar events query...');
                    queryClient.invalidateQueries('calendarEvents', {
                        exact: true,
                        refetchType: 'active',
                        meta: { source: 'customerUpdate' }
                    });
                }).catch(error => {
                    console.error('Error refetching customer:', error);
                });
            } else if (payload.body === 'CUSTOMER_CALENDAR_ERROR') {
                enqueueSnackbar('Error fetching calendars, click here to go to settings and reconnect your calendar', {
                    variant: 'error',
                    dense: true,
                    onClick: () => navigate('/settings/account'),
                });
            } else if (payload.body.startsWith('AI_RESPONSE:')) {
                const response = payload.body.substring('AI_RESPONSE:'.length);
                console.log('AI response received:', response);
            }
        },
        [enqueueSnackbar, queryClient, refetchCustomer, navigate, setIsRescheduling]
    );

    const disconnect = useCallback(() => {
        if (stompClientRef.current?.connected) {
            console.log('Disconnecting existing connection...');
            stompClientRef.current.disconnect();
        }
        if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
            reconnectTimeoutRef.current = null;
        }
        setIsRescheduling(false);
        setStompClient(null);
        stompClientRef.current = null;
        isConnectingRef.current = false;
    }, [setStompClient, setIsRescheduling]);

    const connect = useCallback(() => {
        if (!customer || isTokenExpired || !token || isConnectingRef.current || stompClientRef.current?.connected) {
            return;
        }

        console.log('Connecting to WebSocket...');
        isConnectingRef.current = true;
        disconnect();

        const sock = new SockJS(import.meta.env.VITE_PUBLIC_API_HOST + '/ws');
        const client = over(sock);
        client.debug = null;
        stompClientRef.current = client;

        const onError = (error) => {
            const timestamp = new Date().toISOString();
            console.error(`[${timestamp}] WebSocket connection error:`, error);
            isConnectingRef.current = false;
            disconnect();

            console.log(`[${timestamp}] Reconnect attempt:`, reconnectAttemptRef.current);
            if (reconnectAttemptRef.current < maxReconnectAttempts) {
                const delay = Math.min(30000, baseReconnectDelay * Math.pow(2, reconnectAttemptRef.current));
                console.log(`[${timestamp}] Scheduling reconnect in ${delay}ms...`);

                reconnectTimeoutRef.current = setTimeout(() => {
                    const reconnectTimestamp = new Date().toISOString();
                    console.log(`[${reconnectTimestamp}] Executing reconnect...`);
                    reconnectAttemptRef.current += 1;
                    connect();
                }, delay);
            } else {
                console.log(`[${timestamp}] Max reconnection attempts reached`);
                reconnectAttemptRef.current = 0;
                enqueueSnackbar('Unable to connect to server. Please refresh the page.', {
                    variant: 'error',
                    persist: true,
                });
            }
        };

        const onConnected = () => {
            console.log('WebSocket connected successfully');
            isConnectingRef.current = false;
            reconnectAttemptRef.current = 0;

            const claims = jwtDecode(token);
            const email = claims['https://mindyournow.com/email'];

            client.subscribe(`/topic/${email}-${import.meta.env.VITE_PUBLIC_NODE_ENV}`, onMessageReceived);
            client.subscribe(`/topic/pictures-${import.meta.env.VITE_PUBLIC_NODE_ENV}`, onPictureReceived);

            setStompClient(client);
        };

        client.connect({ Authorization: `Bearer ${token}` }, onConnected, onError);
    }, [
        customer,
        isTokenExpired,
        token,
        disconnect,
        onMessageReceived,
        onPictureReceived,
        setStompClient,
        enqueueSnackbar,
    ]);

    useEffect(() => {
        if (customer && !isTokenExpired && token && !stompClientRef.current?.connected) {
            connect();
        }

        return () => {
            disconnect();
        };
    }, [customer, isTokenExpired, token, connect, disconnect]);

    return null;
};

export default StompClientConnector;
