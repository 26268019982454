import React from 'react';
import { Command } from 'cmdk';
import * as Dialog from '@radix-ui/react-dialog';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { isFocusModeAtom } from '../../atoms/isFocusModeAtom';
import { useSettings } from '../../atoms/SettingsAtom';
import { usePlanning } from '../../hooks/usePlanning';
import { useCustomer } from '../../providers/CustomerProvider';
import { useDeviceType } from '../../hooks/useDeviceType';
import { useTaskModal } from '../../contexts/TaskModalContext';
import AIChatModal from '../../components/ai/AIChatModal';
import './CommandMenu.css';

export function CommandMenu({ open, setOpen }) {
  const navigate = useNavigate();
  const [isFocusMode, setIsFocusMode] = useAtom(isFocusModeAtom);
  const { settings, toggleSetting } = useSettings();
  const { instantPlan, unScheduleAll } = usePlanning();
  const { customer } = useCustomer();
  const { isMobile } = useDeviceType();
  const [isChatModalOpen, setIsChatModalOpen] = React.useState(false);
  const { openTaskModal } = useTaskModal();

  const isPremiumUser = customer?.stripeStatus === 'active' || customer?.id === 1;

  // Add direct shortcut for new task (Alt + T)
  React.useEffect(() => {
    const handleNewTask = (e) => {
      if (e.key === 't' && e.altKey) {
        e.preventDefault();
        openTaskModal();
      }
    };

    document.addEventListener('keydown', handleNewTask);
    return () => document.removeEventListener('keydown', handleNewTask);
  }, [openTaskModal]);

  // Toggle the menu when ⌘K is pressed
  React.useEffect(() => {
    const down = (e) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  const handleOpenChatModal = () => {
    setIsChatModalOpen(true);
  };

  const handleCloseChatModal = () => {
    setIsChatModalOpen(false);
  };

  const actions = [
    // Navigation
    {
      id: 'home',
      name: 'Go to Home',
      shortcut: ['g', 'h'],
      action: () => navigate('/home'),
      category: 'Navigation'
    },
    {
      id: 'organizer',
      name: 'Go to Organizer',
      shortcut: ['g', 'o'],
      action: () => navigate('/organizer'),
      category: 'Navigation'
    },
    {
      id: 'calendar',
      name: 'Go to Calendar',
      shortcut: ['g', 'c'],
      action: () => navigate('/calendar'),
      category: 'Navigation'
    },
    {
      id: 'settings',
      name: 'Open Settings',
      shortcut: ['g', 's'],
      action: () => navigate('/settings'),
      category: 'Navigation'
    },

    // Quick Actions
    {
      id: 'add-task',
      name: 'Add New Task (Alt + T)',
      shortcut: ['Alt', 'T'],
      action: () => {
        openTaskModal();
      },
      category: 'Quick Actions'
    },
    {
      id: 'toggle-focus',
      name: 'Toggle Focus Mode',
      shortcut: ['f', 'm'],
      action: () => setIsFocusMode(!isFocusMode),
      category: 'Quick Actions'
    },
    {
      id: 'instant-plan',
      name: 'Generate New Plan',
      shortcut: ['g', 'p'],
      action: instantPlan,
      category: 'Quick Actions'
    },
    {
      id: 'unschedule-all',
      name: 'Unschedule All Tasks',
      shortcut: ['u', 'a'],
      action: unScheduleAll,
      category: 'Quick Actions'
    },
    // Add AI Chat option only for premium users and non-mobile
    ...(isPremiumUser && !isMobile ? [{
      id: 'ai-chat',
      name: 'Chat with AI',
      shortcut: ['c', 'a'],
      action: handleOpenChatModal,
      category: 'Quick Actions'
    }] : []),

    // Visibility Controls
    {
      id: 'toggle-background',
      name: 'Toggle Background',
      shortcut: ['t', 'b'],
      action: () => toggleSetting('showBackground'),
      category: 'Visibility'
    },
    {
      id: 'toggle-future',
      name: 'Toggle Future Tasks',
      shortcut: ['t', 'f'],
      action: () => toggleSetting('showFuture'),
      category: 'Visibility'
    },
    {
      id: 'toggle-completed',
      name: 'Toggle Completed Tasks',
      shortcut: ['t', 'c'],
      action: () => toggleSetting('showCompleted'),
      category: 'Visibility'
    }
  ];

  return (
    <>
      <Command.Dialog
        open={open}
        onOpenChange={setOpen}
        label="Global Command Menu"
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[640px] max-w-[90vw] rounded-lg border border-gray-200 bg-white p-4 shadow-lg"
      >
        <Dialog.Title className="sr-only">
          Command Menu
        </Dialog.Title>
        <Dialog.Description className="sr-only">
          Search commands and navigate through application features
        </Dialog.Description>
        <Command.Input 
          placeholder="Type a command or search..."
          className="w-full border-none outline-none bg-transparent text-gray-800 placeholder-gray-400 text-lg"
        />
        <Command.List className="mt-4 max-h-[300px] overflow-y-auto">
          <Command.Empty className="py-2 text-gray-400 text-sm">
            No results found.
          </Command.Empty>

          {['Navigation', 'Quick Actions', 'Visibility'].map((category) => (
            <Command.Group key={category} heading={category}>
              {actions
                .filter(action => action.category === category)
                .map((action) => (
                  <Command.Item
                    key={action.id}
                    onSelect={() => {
                      action.action();
                      setOpen(false);
                    }}
                    className="px-2 py-2 rounded cursor-pointer hover:bg-gray-100 flex items-center justify-between"
                  >
                    <span>{action.name}</span>
                    <span className="text-sm text-gray-400">
                      {action.shortcut.join(' + ')}
                    </span>
                  </Command.Item>
                ))}
            </Command.Group>
          ))}
        </Command.List>
      </Command.Dialog>

      {isPremiumUser && !isMobile && (
        <AIChatModal isOpen={isChatModalOpen} onClose={handleCloseChatModal} />
      )}
    </>
  );
}
