import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import {
    CalendarMonth as CalendarMonthIcon,
    Home as HomeIcon,
    Task as TaskIcon,
    Event as EventIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useDeviceType } from './hooks/useDeviceType';
import { AddTaskButton } from './components/AddTaskButton';
import { useTaskModal } from './contexts/TaskModalContext';
import { alpha } from '@mui/material/styles';
import { Sparkles } from 'lucide-react';

const BottomNavBar = ({ navigateValue }) => {
    const { isMobile, smallCalendar } = useDeviceType();
    const { openTaskModal } = useTaskModal();

    if (!isMobile) {
        return null;
    }

    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'background.paper',
                zIndex: 10,
                paddingBottom: 'env(safe-area-inset-bottom)',
                borderTop: 1,
                borderColor: 'divider',
            }}
            elevation={3}
        >
            <BottomNavigation
                value={navigateValue}
                sx={{
                    height: '72px',
                    '& .MuiBottomNavigationAction-root': {
                        padding: '8px 0',
                        minWidth: 0,
                        flex: 1,
                        color: (theme) => theme.palette.primary.main,
                        '&:hover': {
                            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                        },
                        '& .MuiBottomNavigationAction-label': {
                            fontSize: '0.75rem',
                            opacity: 1,
                            transition: 'none',
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: '32px',
                            height: '32px',
                            width: '32px',
                            marginBottom: '2px',
                        },
                    },
                    '& .MuiBottomNavigationAction-wrapper': {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '2px',
                        height: '100%',
                    },
                    '& .add-task-button': {
                        transform: 'scale(1.2)',
                        marginBottom: '4px',
                        '& .MuiSvgIcon-root': {
                            fontSize: '36px',
                            height: '36px',
                            width: '36px',
                        },
                    },
                }}
            >
                <BottomNavigationAction label="Home" component={Link} to="/home" value="/home" icon={<HomeIcon />} />
                <BottomNavigationAction
                    label="Events"
                    component={Link}
                    to="/upcoming-events"
                    value="/upcoming-events"
                    icon={<EventIcon />}
                />
                <BottomNavigationAction
                    icon={
                        <AddTaskButton
                            className="flex justify-center items-center"
                            iconProps={{
                                sx: {
                                    fontSize: 28,
                                    height: 28,
                                    width: 28,
                                    margin: 0,
                                },
                            }}
                            openTaskModal={openTaskModal}
                        />
                    }
                />
                <BottomNavigationAction
                    to="/calendar"
                    component={Link}
                    label="Calendar"
                    value="/calendar"
                    icon={<CalendarMonthIcon />}
                />
                <BottomNavigationAction
                    component={Link}
                    to="/ai-chat"
                    value="/ai-chat"
                    label="AI Chat"
                    icon={<Sparkles size={24} />}
                />
            </BottomNavigation>
        </Paper>
    );
};

export default BottomNavBar;
