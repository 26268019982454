// Libraries
import React, { Suspense } from 'react';
// Add this import at the top with other imports
// import './axiosInterceptor';

// Third-party modules
import { Capacitor } from '@capacitor/core';
import { BrowserRouter } from 'react-router-dom';
import { registerSW } from 'virtual:pwa-register';
import { SnackbarProvider } from 'notistack';
import { Provider as JotaiProvider } from 'jotai/react';

import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/react';
// import TimeAgo from 'javascript-time-ago';
// import en from 'javascript-time-ago/locale/en.json';
import ReactGA from 'react-ga4';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Our modules
import { Loading } from './components/utils/Loading';
import { initReferral } from './utils/referral';

import App from './app.jsx';

// Initialize referral tracking
initReferral();

// Stylesheets and assets
import './global.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'intro.js/introjs.css';

console.log('Environment: ' + import.meta.env.VITE_PUBLIC_NODE_ENV);

if (import.meta.env.VITE_PUBLIC_NODE_ENV == 'prod') {
    Sentry.init({
        dsn: 'https://d134cdeb503f43979bf2a1bd0023c724@o1098528.ingest.sentry.io/6122862',
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        // integrations: [new Sentry.Replay()],
    });
}

console.log(
    '%c STOP ',
    'background: #c41e3a; color: white; font-size: 40px; font-weight: bold; padding: 20px 10px; ' +
        'clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%); ' +
        'border: 5px solid white; text-align: center; text-shadow: 2px 2px 4px rgba(0,0,0,0.3); ' +
        'display: inline-block; min-width: 100px; transform: rotate(0deg); ' +
        'font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;'
);

console.log('%cThis is a browser feature intended for developers.', 'font-size: 30px');
console.log(
    "%cIf someone told you to copy-paste something here to enable a Mind Your Now feature or 'hack' someone's account, it is a scam and will give them access to your Mind Your Now account.",
    'font-size:40px'
);
console.log('%cSee https://en.wikipedia.org/wiki/Self-XSS for more information', 'font-size:20px');
console.log('');
console.log('V11.0.0');

const subMonthly = 'monthly_subscription';
const subAnnual = 'annual_subscription';

const queryClient = new QueryClient();

const intervalMS = 10 * 60 * 1000;
const theme = createTheme({
    mixins: {
        toolbar: {
            // Or whatever value you need
            minHeight: 'env(safe-area-inset-top)',
        },
    },
});

// Function to determine if the app is running on a mobile device
const isMobileDevice = () => {
    if (window.Capacitor) {
        const platform = Capacitor.getPlatform();
        return platform === 'ios' || platform === 'android';
    } else {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
};

// Only register the service worker if it's not a mobile device
// if (!isMobileDevice()) {
//     const updateSW = registerSW({
//         onRegistered(r) {
//             r &&
//                 r.addEventListener('updatefound', () => {
//                     const installingWorker = r.installing;
//                     installingWorker.addEventListener('statechange', () => {
//                         if (installingWorker.state === 'installed' && navigator.serviceWorker.controller) {
//                             window.location.reload();
//                         }
//                     });
//                 });
//         },
//         additionalManifestEntries: [{ url: '/index.html', revision: Date.now().toString() }],
//         exclude: ['index.html'],
//     });
// }

if (!isMobileDevice()) {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
            for (let registration of registrations) {
                registration.unregister();
            }
        });
    }
}

// if (!isMobileDevice()) {
//     const updateSW = registerSW({
//         onNeedRefresh() {
//             // Prompt the user to refresh the page
//             if (window.confirm('A new version of the app is available. Reload to update?')) {
//                 updateSW(true);
//             }
//         },
//         onOfflineReady() {
//             console.log('App is ready for offline use');
//         },
//         onRegistered(swRegistration) {
//             if (swRegistration) {
//                 setInterval(
//                     () => {
//                         swRegistration.update();
//                     },
//                     60 * 60 * 1000
//                 ); // Check for updates every hour
//             }
//         },
//         onRegisterError(error) {
//             console.error('SW registration error', error);
//         },
//     });
// }

// TimeAgo.addDefaultLocale(en);

ReactGA.initialize('G-HC3CNRJ6WD', {
    gaOptions: {
        siteSpeedSampleRate: 1,
        cookieFlags: 'SameSite=None;Secure',
        cookieDomain: 'none',
        storage: 'none',
    },
    gtagOptions: {
        consent: 'default',
    },
});
ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Index' });
ReactGA.event({
    category: 'General Category',
    action: 'IndexAction',
});

let old = alert;

alert = function () {
    console.log(new Error().stack);
    old.apply(window, arguments);
};

if (
    !new (class {
        x;
    })().hasOwnProperty('x')
)
    throw new Error('Transpiler is not configured correctly');

const snackBarStyles = {
    base: {
        fontSize: 50,
    },
};

const container = document.getElementById('root');

const root = createRoot(container); //

root.render(
    // <QueryClientProvider client={queryClient} contextSharing={true}>
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}

            {/* <React.StrictMode> */}

            <JotaiProvider>
                <QueryClientProvider client={queryClient}>
                    <SnackbarProvider
                        sx={{
                            '& .SnackbarContent-root': {
                                fontSize: 20,
                                //width: 600
                            },
                        }}
                        classes={{
                            base: snackBarStyles.base,
                        }}
                        maxSnack={3}
                    >
                        <Suspense fallback={<Loading />}>
                            {/* <ReactQueryDevtools initialIsOpen={false} /> */}

                            {/* <Provider config={rollbarConfig}> */}
                            {/* ErrorBoundary catches all React errors in the tree below and logs them to Rollbar */}
                            {/* <ErrorBoundary> */}

                            <App />

                            {/* </ErrorBoundary> */}
                            {/* </Provider> */}
                        </Suspense>
                    </SnackbarProvider>
                </QueryClientProvider>
            </JotaiProvider>

            {/* </React.StrictMode> */}
        </BrowserRouter>
    </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// serviceWorker.register();
