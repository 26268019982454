import React, { useState, useEffect, useCallback } from 'react';
import { DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faCouch, faCalendarWeek } from '@fortawesome/free-solid-svg-icons';
import CalendarIcon from './CalendarIcon';
import moment from 'moment';
import { useTaskSchedule } from './hooks/useTaskSchedule';
import { useTaskActions } from './hooks/useTaskActions';

import criticalImage from '/images/noun-exclamation-green.svg';
import overTheHorizonImage from '/images/noun-horizon-orange.svg';
import opportunityNowImage from '/images/noun-opportunity-blue.svg';
import parkingLotImage from '/images/noun-parking-lot-red.svg';

export default function TaskDatePickerToolbar({    // Destructure all custom props
    setToday,
    setTomorrow,
    setNextWeekend,
    setNextWeek,
    task,
    updateTask,
    onClear,
    onAccept,
    onDismiss,
    setDate,
    setPriorities,
    otherPriorities,
    isValid,
    isRtl,
    wrapperVariant,
    taskStartDate,
    slotProps,
    onSetToday,
    onOpen,
    onSelectShortcut,

    ...props}) {
    const { taskSchedule } = useTaskSchedule();

    const [resetTimeout, setResetTimeout] = useState(null);

    const today = moment().format('ddd MMM D');
    const isWeekend = moment().isoWeekday() >= 6;
    const weekendLabel = isWeekend ? 'Next Weekend' : 'This Weekend';
    const nextWeekDay = moment().add(1, 'week').startOf('isoWeek').format('ddd MMM D');

    // Calculate the next weekend date
    const getNextWeekendDate = () => {
        const today = moment();
        const thisSaturday = moment().isoWeekday(6); // This week's Saturday
        
        // If we've passed Saturday this week, get next Saturday
        if (today.isAfter(thisSaturday)) {
            return thisSaturday.add(1, 'week').format('ddd MMM D');
        }
        
        return thisSaturday.format('ddd MMM D');
    };

    const weekendDate = getNextWeekendDate();

    const isSaturday = moment().isoWeekday() === 6;
    const isSunday = moment().isoWeekday() === 7;

    const priorities = ['CRITICAL', 'OPPORTUNITY_NOW', 'OVER_THE_HORIZON', 'PARKING_LOT'];
    const icons = [criticalImage, opportunityNowImage, overTheHorizonImage, parkingLotImage];

    const toCapitalizedWords = (name) =>
        name
            .replace(/_/g, ' ')
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');

    const palette = {
        CRITICAL: { main: '#0C803D', contrastText: '#fff' },
        OPPORTUNITY_NOW: { main: '#107CC4', contrastText: '#fff' },
        OVER_THE_HORIZON: { main: '#F9913B', contrastText: '#fff' },
        PARKING_LOT: { main: '#F04F23', contrastText: '#fff' },
    };

    const priorityStartDates = priorities.map((priority) => {
        switch (priority) {
            case 'CRITICAL':
                return new Date();
            case 'OVER_THE_HORIZON':
                return taskSchedule?.overTheHorizonDate;
            case 'OPPORTUNITY_NOW':
                return taskSchedule?.opportunityNowDate;
            case 'PARKING_LOT':
                return taskSchedule?.parkingLotDate;
            default:
                return null;
        }
    });

    useEffect(() => {
        return () => {
            if (resetTimeout) {
                clearTimeout(resetTimeout);
            }
        };
    }, [resetTimeout]);

    const handleMouseEnter = (date) => {
        //   if (resetTimeout) {
        //     clearTimeout(resetTimeout);
        //   }
        // if (props.setDate && date !== props.taskStartDate) {
        //     props.setDate(date);
        // }
    };

    const handleMouseLeave = () => {
        //   if (resetTimeout) {
        //     clearTimeout(resetTimeout);
        //   }
        //   setResetTimeout(
        //     setTimeout(() => {
        //       if (props.setDate) {
        //         props.setDate(props.taskStartDate);
        //       }
        //     }, 2000) // Delay in milliseconds before resetting the date
        //   );
    };

    const handleSetPriority = useCallback(
        (priority) => {
            const priorityIndex = priorities.indexOf(priority);
            const startDate = priorityStartDates[priorityIndex];
            const updatedTask = { 
                ...task, 
                priority,
                startDate: startDate
            };
            updateTask(updatedTask);
            if (setDate && startDate) {
                setDate(startDate);
            }
        },
        [task, updateTask, setDate, priorities, priorityStartDates]
    );

    return (
        <div className="flex flex-col px-3 border-b border-gray-400">
            <DatePickerToolbar {...props} />
            <div>
                {[
                    { label: 'Today', icon: <CalendarIcon size={36} />, onClick: setToday, date: today },
                    {
                        label: 'Tomorrow',
                        icon: <FontAwesomeIcon icon={faSun} size="lg" color="#FFD700" />,
                        onClick: setTomorrow,
                        date: moment().add(1, 'day').format('ddd MMM D'),
                    },
                    {
                        label: weekendLabel,
                        icon: <FontAwesomeIcon icon={faCouch} size="lg" color="#8B4513" />,
                        onClick: () => {
                            const thisSaturday = moment().isoWeekday(6); // This week's Saturday
                            if (moment().isAfter(thisSaturday)) {
                                thisSaturday.add(1, 'week');
                            }
                            setNextWeekend(thisSaturday.toDate());
                        },
                        date: weekendDate,
                    },
                    {
                        label: 'Next Week',
                        icon: <FontAwesomeIcon icon={faCalendarWeek} size="lg" color="#6A148E" />,
                        onClick: setNextWeek,
                        date: nextWeekDay,
                    },
                ].map(({ label, icon, onClick, date }) => (
                    <div
                        key={label}
                        className="flex justify-between items-center py-0 rounded-lg cursor-pointer hover:bg-gray-200 task-date-option"
                        onClick={onClick}
                        onMouseEnter={() => handleMouseEnter(moment(date, 'ddd MMM D').toDate())}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div className="flex items-center">
                            <div className="flex justify-center w-6">{icon}</div>
                            <Button
                                size="small"
                                style={{
                                    marginLeft: '7px',
                                    textTransform: 'none',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    color: '#4A4A4A',
                                }}
                            >
                                {label}
                            </Button>
                        </div>
                        <span className="text-gray-500" style={{ fontSize: '12px' }}>
                            {date}
                        </span>
                    </div>
                ))}
                <hr style={{ border: '0', borderTop: '2px solid #ddd' }} />
                {priorities.map((priority, index) => (
                    <div
                        key={priority}
                        className="flex justify-between items-center py-0 rounded-lg cursor-pointer hover:bg-gray-200 task-date-option"
                        onClick={() => handleSetPriority(priority)}
                        onMouseEnter={() => handleMouseEnter(priorityStartDates[index])}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div className="flex items-center">
                            <div className="flex justify-center w-6">
                                <img src={icons[index]} alt="priority icon" />
                            </div>
                            <Button
                                size="small"
                                style={{
                                    marginLeft: '7px',
                                    textTransform: 'none',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    color: palette[priority].main,
                                }}
                            >
                                {toCapitalizedWords(priority)}
                            </Button>
                        </div>
                        <span className="text-gray-500" style={{ fontSize: '12px' }}>
                            {priorityStartDates[index] ? moment(priorityStartDates[index]).format('ddd MMM D') : 'N/A'}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}
