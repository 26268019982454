import { Task } from '../domain/Task';

interface TaskHistoryEntry {
    tasks: Task[];
    description: string;
    timestamp: Date;
}

export class TaskHistory {
    private history: TaskHistoryEntry[] = [];
    private currentIndex: number = -1;
    private maxHistory: number = 50;

    pushState(tasks: Task[], description: string) {
        // Remove any future history if we're not at the latest state
        if (this.currentIndex < this.history.length - 1) {
            this.history = this.history.slice(0, this.currentIndex + 1);
        }

        // Add new state
        this.history.push({
            tasks: tasks.map((task) => ({ ...task })), // Deep copy tasks
            description,
            timestamp: new Date(),
        });

        // Maintain history limit
        if (this.history.length > this.maxHistory) {
            this.history = this.history.slice(this.history.length - this.maxHistory);
        }

        this.currentIndex = this.history.length - 1;
    }

    undo(): TaskHistoryEntry | null {
        if (this.currentIndex > 0) {
            this.currentIndex--;
            return this.history[this.currentIndex];
        }
        return null;
    }

    redo(): TaskHistoryEntry | null {
        if (this.currentIndex < this.history.length - 1) {
            this.currentIndex++;
            return this.history[this.currentIndex];
        }
        return null;
    }

    canUndo(): boolean {
        return this.currentIndex > 0;
    }

    canRedo(): boolean {
        return this.currentIndex < this.history.length - 1;
    }

    getCurrentState(): TaskHistoryEntry | null {
        return this.history[this.currentIndex] || null;
    }
}

export const taskHistory = new TaskHistory();
