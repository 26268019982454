import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import rehypeSanitize from 'rehype-sanitize';
import { SendHorizontal, User, Sparkles, Trash2 } from 'lucide-react';
import { Box, Typography, IconButton, TextField, InputAdornment } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAIChat } from '../utils/useAIChat';

const AIChat = () => {
    const { chatMessages, handleUserInput, isLoading, clearChat } = useAIChat();
    const [userMessage, setUserMessage] = React.useState('');
    const messagesEndRef = React.useRef(null);
    const theme = useTheme();

    React.useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatMessages]);

    const handleSendMessage = async () => {
        if (userMessage.trim()) {
            const messageToSend = userMessage;
            setUserMessage('');
            try {
                await handleUserInput(messageToSend);
            } catch (error) {
                console.error('AIChat - Error sending message:', error);
            }
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const ThinkingIndicator = () => (
        <Box 
            sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                p: 2,
                bgcolor: 'grey.50',
                borderRadius: 2,
                maxWidth: '80%'
            }}
        >
            <Box sx={{ display: 'flex', gap: 1 }}>
                <Box
                    sx={{
                        width: 8,
                        height: 8,
                        bgcolor: 'primary.main',
                        borderRadius: '50%',
                        animation: 'bounce 0.6s infinite',
                        animationDelay: '-0.3s'
                    }}
                />
                <Box
                    sx={{
                        width: 8,
                        height: 8,
                        bgcolor: 'primary.main',
                        borderRadius: '50%',
                        animation: 'bounce 0.6s infinite',
                        animationDelay: '-0.15s'
                    }}
                />
                <Box
                    sx={{
                        width: 8,
                        height: 8,
                        bgcolor: 'primary.main',
                        borderRadius: '50%',
                        animation: 'bounce 0.6s infinite'
                    }}
                />
            </Box>
            <Typography variant="body2" color="text.secondary">
                Kaia is thinking...
            </Typography>
        </Box>
    );

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: { xs: 2, sm: 3 },
                    py: 2,
                    borderBottom: 1,
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    ml: { xs: 5, sm: 6 }
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Sparkles size={20} className="text-blue-500" />
                    <Typography variant="h6" component="h1">
                        Kaia AI Assistant
                    </Typography>
                </Box>
                <IconButton
                    onClick={clearChat}
                    size="small"
                    sx={{
                        '&:hover': {
                            bgcolor: 'action.hover'
                        }
                    }}
                    aria-label="Clear chat"
                >
                    <Trash2 size={20} />
                </IconButton>
            </Box>

            <Box
                sx={{
                    flex: 1,
                    overflowY: 'auto',
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    bgcolor: theme.palette.grey[50]
                }}
            >
                {Array.isArray(chatMessages) && chatMessages.length > 0 ? (
                    chatMessages.map((message) => (
                        <Box
                            key={message.id}
                            sx={{
                                display: 'flex',
                                gap: 2,
                                justifyContent: message.isUser ? 'flex-end' : 'flex-start',
                                maxWidth: '100%'
                            }}
                        >
                            {!message.isUser && (
                                <Sparkles size={24} className="flex-shrink-0 text-blue-500" />
                            )}
                            <Box
                                sx={{
                                    maxWidth: '80%',
                                    p: 2,
                                    borderRadius: 2,
                                    bgcolor: message.isUser ? 'primary.main' : 'background.paper',
                                    color: message.isUser ? 'primary.contrastText' : 'text.primary',
                                    boxShadow: 1
                                }}
                            >
                                <ReactMarkdown
                                    remarkPlugins={[remarkGfm, remarkBreaks]}
                                    rehypePlugins={[rehypeSanitize]}
                                    className={`prose ${
                                        message.isUser ? 'dark:prose-invert text-white' : ''
                                    } max-w-none [&_table]:border-collapse [&_table]:w-full [&_th]:border [&_th]:p-2 [&_th]:bg-gray-50 [&_td]:border [&_td]:p-2 whitespace-pre-wrap`}
                                >
                                    {message.text}
                                </ReactMarkdown>
                            </Box>
                            {message.isUser && (
                                <User size={24} className="flex-shrink-0 text-blue-500" />
                            )}
                        </Box>
                    ))
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                        }}
                    >
                        <Typography color="text.secondary">
                            Start a conversation with Kaia
                        </Typography>
                    </Box>
                )}
                {isLoading && (
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Sparkles size={24} className="flex-shrink-0 text-blue-500" />
                        <ThinkingIndicator />
                    </Box>
                )}
                <div ref={messagesEndRef} />
            </Box>

            <Box
                sx={{
                    p: 2,
                    borderTop: 1,
                    borderColor: 'divider',
                    bgcolor: 'background.paper'
                }}
            >
                <TextField
                    fullWidth
                    placeholder="Type your message..."
                    value={userMessage}
                    onChange={(e) => setUserMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                    multiline
                    maxRows={4}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton 
                                    onClick={handleSendMessage}
                                    disabled={!userMessage.trim() || isLoading}
                                    color="primary"
                                    sx={{
                                        '&:hover': {
                                            bgcolor: 'primary.main',
                                            color: 'white'
                                        }
                                    }}
                                >
                                    <SendHorizontal size={20} />
                                </IconButton>
                            </InputAdornment>
                        ),
                        sx: {
                            borderRadius: 30,
                            py: 1,
                            px: 2,
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderWidth: 2
                            }
                        }
                    }}
                />
            </Box>
        </Box>
    );
};

export default AIChat;
