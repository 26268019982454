// Libraries
import React, { useCallback, useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';

// Third-party modules

import { Link, Outlet, useNavigate } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import CookieConsent from 'react-cookie-consent';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Steps } from 'intro.js-react';

import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga4';
import { useAtom } from 'jotai';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Our modules
import Notifier from './components/Notifier';
import PreviewBanner from './components/PreviewBanner';
import { CommandMenu } from './components/CommandMenu';
import { isAuthenticatedAtom } from './atoms/authAtoms';
import { AppListener } from './components/utils/AppListener';
import AppRoutes from './AppRoutes';
import BottomNavBar from './BottomNavBar';

// import NotificationsProvider
import { NotificationsProvider } from './components/NotificationsContext';

// Hooks

import { needsTutorialAtom } from './components/utils/GlobalAtoms';
import useIntroTutorial from './hooks/useIntroTutorial';
import { useDeviceType } from './hooks/useDeviceType';

import { CustomerProvider } from './providers/CustomerProvider';
import { tokenAtom } from './atoms/tokenAtoms';

// Images/assets
import logo_no_text from '../public/images/logo-no-text.png';

import { useSettings } from './atoms/SettingsAtom';

import { TaskModalProvider } from './contexts/TaskModalContext';
import { TaskModal } from './components/tasks/TaskModal';
import { CurrentPictureProvider } from './contexts/CurrentPictureContext';
import { TasksProvider } from './providers/TasksProvider';

import InlineLoading from './components/InlineLoading';

import Focus from './components/Focus';

const MemoizedAppRoutes = AppRoutes;

const Title = () => (
    <Link to={{ pathname: '/' }}>
        <div className="flex mt-5 ml-[-45px] text-xl align-middle flex-inline flex-grow ">
            <span className="align-middle font-regular text-[#00AEEF]">mindy</span>
            <span className="font-regular align-middle text-[#80D7F7]">your</span>
            <span className="align-middle font-regular text-[#FFC60B]">now</span>
        </div>
    </Link>
);

const Logo = () => (
    <Link to={{ pathname: '/' }}>
        <div className="logo w-[100px] mt-2 ml-2">
            <img className="" src={logo_no_text} height="50px" width="50px" alt="Logo for Mind Your Now"></img>
        </div>
    </Link>
);

function App() {
    const { settings, updateSetting } = useSettings();

    // No need for `showSteps` state if it's only based on `needsTutorial`
    const [needsTutorial, setNeedsTutorial] = useAtom(needsTutorialAtom);

    const navigate = useNavigate();
    const [navigateValue, setNavigateValue] = useState();

    const [newTask, setNewTask] = useState(null);
    const { isMobile, smallCalendar } = useDeviceType();
    const [isAuthenticated] = useAtom(isAuthenticatedAtom);
    const [token] = useAtom(tokenAtom);
    const canQueryCustomer = !!isAuthenticated && !!token;
    const [backgroundImage, setBackgroundImage] = useState();

    const [showSteps, setShowSteps] = useState(false);

    const canQueryTasks = !!token;

    // });
    if (!isMobile) {
        setNeedsTutorial(false);
    }
    const completeTutorial = useCallback(() => {
        if (showSteps) {
            // Trigger GA event for completing the tutorial
            ReactGA.event({
                category: 'User',
                action: 'Completed Tutorial',
            });
            document
                .querySelectorAll('.introjs-overlay, .introjs-helperLayer, .introjs-tooltipReferenceLayer')
                .forEach((el) => el.remove());
            document.body.classList.remove('introjs-fixParent');
            setShowSteps(false);
            setNeedsTutorial(false);
        }
    });

    // Start the tutorial once the customer data is loaded, if a tutorial is needed, and BottomNavBar is rendered
    // useEffect(() => {
    //     if (customer && needsTutorial) {
    //         const delayTourStart = setTimeout(() => {
    //             // Ensure all target elements are loaded before showing the steps
    //             const allElementsLoaded =
    //                 document.querySelector('.bottom-add-task-button') &&
    //                 document.querySelector('.calendar-class') &&
    //                 document.querySelector('.planner-class');
    //             if (allElementsLoaded) {
    //                 setShowSteps(true);
    //             } else {
    //                 // If not loaded, delay the tour start further
    //                 clearTimeout(delayTourStart);
    //                 setTimeout(() => setShowSteps(true), 500); // Adjust delay as necessary
    //             }
    //         }, 500); // Start checking after 500ms

    //         // Cleanup timeout if the component unmounts
    //         return () => clearTimeout(delayTourStart);
    //     }
    // }, [customer, needsTutorial, setShowSteps]);
    useEffect(() => {
        // set show future to false
        updateSetting('showFuture', false);

        if (needsTutorial && !isMobile) {
            const allElementsLoaded =
                document.querySelector('.bottom-add-task-button') &&
                document.querySelector('.calendar-class') &&
                document.querySelector('.planner-class');

            if (allElementsLoaded) {
                ReactGA.event({
                    category: 'User',
                    action: 'Started Tutorial',
                });
                setShowSteps(true);
            } else {
                const delayTourStart = setTimeout(() => setShowSteps(true), 1000);
                return () => clearTimeout(delayTourStart);
            }
        }
    }, [needsTutorial]);

    // useEffect(() => {
    //     if (!customer?.accounts?.length) {
    //         return;
    //     }
    //     // If there is an error in any of the calendar accounts, let's navigate customer
    //     // to the settings page but only if we are not already at the settings page
    //     if (window.location.pathname !== '/settings' && customer.accounts.some(account => account.error && !isMobile)) {
    //         // Print error object on each account
    //         customer.accounts.forEach(account => {
    //             if (account.error) {
    //                 console.log("Error for account: " + account.accountType + " - " + account.accountName + " - " + account.accountId);
    //                 console.log(account.error);
    //             }
    //         });
    //         console.log(customer.accounts.some(account => account.error));
    //         navigate('/settings');
    //     }
    // }, [customer]);

    const handleAcceptCookie = () => {
        // Update Google Analytics with user's consent
        ReactGA.gtag('consent', 'update', {
            analytics_storage: 'granted',
        });

        // You can also track this event
        ReactGA.event({
            category: 'User',
            action: 'Accepted Cookies',
        });
    };

    // Function to call when a specific step is about to change
    const handleBeforeChange = (nextStepIndex) => {
        // if (nextStepIndex === 0) {
        //     // Assuming you have a CSS class 'introjs-hide' that applies 'display: none'
        //     document.querySelector('.introjs-prevbutton').classList.add('introjs-hide');
        // } else {
        //     // Make sure to remove the class if it's not the first step
        //     document.querySelector('.introjs-prevbutton').classList.remove('introjs-hide');
        // }

        // Check if nextStepIndex matches the step you want to execute code on
        if (nextStepIndex === 4) {
        }
        if (nextStepIndex === 5) {
            navigate('/calendar');
        }
        if (nextStepIndex === 6) {
            navigate('/planner');
        }
        if (nextStepIndex === 7) {
            navigate('/calendar'); // Changed from '/home' to '/calendar'
        }
        if (nextStepIndex == 9) {
            completeTutorial();
        }
    };

    const introOptions = {
        nextLabel: 'Next',
        prevLabel: 'Back',
        skipLabel: 'X',
        doneLabel: 'Done', // Explicitly set the label for the 'Done' button
        exitOnEsc: true,
        exitOnOverlayClick: false,
        showButtons: true, // Make sure buttons are shown
        showBullets: true,
        scrollToElement: true,
        showStepNumbers: false,
        keyboardNavigation: true,
        hidePrev: true, // Ensure the "Back" button is hidden by default
        hideNext: false, // Ensure the "Next" button is shown by default
        dontShowAgain: true,
        dontShowAgainCookie: 'dontshowagaincookie-6',
        // ... any other options you want to configure
    };
    const introSteps = [
        {
            element: '.class-home',
            intro: `Welcome to <span class="text-[#00AEEF]">mind</span><span class="font-normal text-[#80D7F7]">your</span><span class="text-[#FFC60B]">now</span>!<br/><br/> Let's take a brief tour.`,
        },
        {
            element: '.add-task-button',
            intro: 'Click the <b>+</b> to add new tasks.',
        },
        {
            element: '.bottom-add-task-button',
            intro: 'You can also add a task with this <b>+</b> button at the bottom of your screen.',
        },
        {
            element: '.briefing',
            position: 'right',
            intro: 'This is your briefing, which will show you your tasks and upcoming events.',
        },
        {
            element: '.upcoming-events',
            position: 'left',
            intro: 'Connect your Google and Microsoft Outlook calendars to autoschedule your tasks.',
        },
        {
            element: '.calendar-class',
            intro: `After connecting your calendars, you can view them here. `,
        },
        {
            element: '.planner-class',
            intro: 'Use the planner to plan your days.',
        },
        {
            element: '.actionbarwrapper',
            intro: 'Here you can turn off background images, view future tasks, reschedule your tasks, and edit your settings.',
        },
        {
            element: '#central-element',
            intro: '<br>Enjoy <span class="text-[#00AEEF]">mind</span><span class="font-normal text-[#80D7F7]">your</span><span class="text-[#FFC60B]">now</span>!',
            position: 'top',
            // When Intro.js reaches this step, it will show a "Done" button with this label
            doneLabel: 'Done',
        },
    ];

    return (
        <div
            id="app"
            className="justify-center m-auto border-box"
            style={{
                paddingTop: 'calc(env(safe-area-inset-top, 0px))',
                paddingBottom: 'calc(env(safe-area-inset-bottom, 0px))',
                paddingLeft: 'calc(env(safe-area-inset-left, 0px))',
                paddingRight: 'calc(env(safe-area-inset-right, 0px))',

                backgroundImage: settings.showBackground ? `url(${backgroundImage})` : 'none',
                backgroundAttachment: 'fixed',
                transition: 'background-size 20s ease-in-out',
            }}
            // onClick={(e) => cancelEdit(e)}
        >
            <CurrentPictureProvider>
                <CustomerProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TasksProvider>
                            <TaskModalProvider>
                                <TaskModal />
                                <Focus />
                                <NotificationsProvider>
                                    <DndProvider backend={HTML5Backend}>
                                        <GoogleOAuthProvider clientId="853098501553-1ei07dr8jl4aq95d30jc2cm387viev40.apps.googleusercontent.com">
                                            <Notifier />
                                            <PreviewBanner />
                                            <AppListener />
                                            <CommandMenu />
                                            <MemoizedAppRoutes />
                                            {showSteps && (
                                                <Steps
                                                    enabled={true}
                                                    steps={introSteps}
                                                    initialStep={0}
                                                    onExit={() => {}}
                                                    onComplete={completeTutorial}
                                                    onSkip={completeTutorial}
                                                    options={introOptions}
                                                    onBeforeChange={(element) => handleBeforeChange(element)}
                                                />
                                            )}
                                            <div className={isMobile ? 'mobile-content' : 'main-content'}>
                                                <Outlet />
                                                {isAuthenticated && <BottomNavBar navigateValue={navigateValue} />}
                                            </div>

                                            {!Capacitor.isNativePlatform() && (
                                                <CookieConsent
                                                    location="bottom"
                                                    buttonText="I understand"
                                                    cookieName="PrivacyConsentCookie"
                                                    style={{ background: '#00AEEF', fontSize: '18px' }}
                                                    buttonStyle={{
                                                        background: '#FFC60B',
                                                        color: '#000000',
                                                        fontSize: '20px',
                                                    }}
                                                    onAccept={handleAcceptCookie}
                                                    enableDeclineButton
                                                    declineButtonText="Decline"
                                                    onDecline={() => {
                                                        ReactGA.gtag('consent', 'update', {
                                                            analytics_storage: 'denied',
                                                        });
                                                    }}
                                                >
                                                    This website uses cookies to enhance the user experience.
                                                </CookieConsent>
                                            )}
                                        </GoogleOAuthProvider>
                                    </DndProvider>
                                </NotificationsProvider>
                            </TaskModalProvider>
                        </TasksProvider>
                    </LocalizationProvider>
                </CustomerProvider>
            </CurrentPictureProvider>
        </div>
    );
}

export default App;
