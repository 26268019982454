import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import rrulePlugin from '@fullcalendar/rrule';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useCalendarEvents } from '../../contexts/CalendarEventsContext';
import InlineLoading from '../InlineLoading';
import { useDeviceType } from '../../hooks/useDeviceType';
import GoogleWorkspaceIcon from './GoogleWorkspaceIcon';
import MicrosoftCalendarIcon from './MicrosoftCalendarIcon';
import { useCustomer } from '../../providers/CustomerProvider';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

export const UpcomingEvents = () => {
    const { calendarEvents, isLoading } = useCalendarEvents();
    const { customer } = useCustomer();
    const [events, setEvents] = useState([]);
    const [key, setKey] = useState(0);
    const calendarRef = useRef(null);
    const [hasReceivedEvents, setHasReceivedEvents] = useState(false);
    const { isMobile } = useDeviceType();

    // Check if customer has no accounts or empty accounts array
    if (!customer?.accounts || customer.accounts.length === 0) {
        return (
            <div className="w-full h-full text-black bg-gradient-to-b from-blue-50 to-blue-100 upcoming-events">
                <div className="flex">
                    <div className="flex-1 mb-2 ml-0 text-2xl font-bold text-blue-950">Upcoming Events</div>
                </div>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="relative p-6 bg-white rounded-lg shadow-sm"
                >
                    <div className="mb-8 text-center">
                        <FontAwesomeIcon icon={faCalendarAlt} className="mb-4 text-5xl text-blue-600" />
                        <h2 className="mb-2 text-2xl font-bold text-blue-900">No Calendar Connected</h2>
                        <p className="mb-6 text-blue-700">
                            Connect your calendar to see all your upcoming events in one place
                        </p>
                    </div>

                    <div className="grid grid-cols-1 gap-4 mx-auto mb-8 max-w-2xl md:grid-cols-2">
                        {[
                            'Autoschedule your tasks',
                            'Never miss an important meeting',
                            'Sync with Google and Microsoft calendars',
                            'Setup Habits and Recurring tasks',
                        ].map((benefit, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                className="flex items-center p-4 bg-blue-50 rounded-lg"
                            >
                                <FontAwesomeIcon icon={faCheckCircle} className="mr-3 text-green-500" />
                                <span className="text-blue-800">{benefit}</span>
                            </motion.div>
                        ))}
                    </div>

                    <div className="text-center">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <Link
                                to="/settings"
                                className="inline-flex items-center px-6 py-3 text-white bg-blue-600 rounded-lg shadow-md transition-colors hover:bg-blue-700"
                            >
                                <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                                Connect Your Calendar Now
                            </Link>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        );
    }

    const processEvents = useCallback(() => {
        if (!calendarEvents || !Array.isArray(calendarEvents)) return [];

        const now = new Date();
        const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        const oneHourAgo = new Date(now.getTime() - 1 * 60 * 60 * 1000);
        const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        let futureEvents = calendarEvents.filter((event) => {
            let eventStart = new Date(event.start);
            const eventEnd = event.end ? new Date(event.end) : eventStart;

            // Change the start date to start of today if it is more than 24 hours old
            if (eventStart < twentyFourHoursAgo && eventEnd > now) {
                event.start = startOfToday.toISOString();
                eventStart = startOfToday;
            }

            // Filter events that haven't ended more than an hour ago
            return eventEnd >= oneHourAgo;
        });

        futureEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

        return futureEvents.slice(0, 10);
    }, [calendarEvents]);

    useEffect(() => {
        if (!isLoading && calendarEvents && calendarEvents.length > 0) {
            const processedEvents = processEvents();
            setEvents(processedEvents);
            setKey((prevKey) => prevKey + 1);
            setHasReceivedEvents(true);
        }
    }, [calendarEvents, isLoading, processEvents]);

    useEffect(() => {
        if (calendarRef.current && events.length > 0) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.removeAllEvents();
            calendarApi.addEventSource(events);

            const today = new Date();
            const eventsToday = events.filter((event) => {
                const eventStart = new Date(event.start);
                return (
                    eventStart.getDate() === today.getDate() &&
                    eventStart.getMonth() === today.getMonth() &&
                    eventStart.getFullYear() === today.getFullYear()
                );
            });

            if (eventsToday.length === 0) {
                const nextEvent = events.find((event) => new Date(event.start) > today);
                if (nextEvent) {
                    calendarApi.gotoDate(new Date(nextEvent.start));
                }
            } else {
                calendarApi.gotoDate(today);
            }
        }
    }, [events]);

    const handleEventClick = useCallback((info) => {
        console.log('Event clicked:', info.event);
    }, []);

    const renderEventContent = useCallback(
        (eventInfo) => {
            const MAX_TITLE_LENGTH = 40;
            const { event } = eventInfo;
            const title =
                event.title.length > MAX_TITLE_LENGTH
                    ? `${event.title.substring(0, MAX_TITLE_LENGTH)}...`
                    : event.title;

            const now = new Date();
            const eventStart = new Date(event.start);
            const eventEnd = event.end ? new Date(event.end) : new Date(event.start);

            const isCurrentEvent = eventStart <= now && now <= eventEnd;
            const backgroundColor = isCurrentEvent ? 'bg-green-50' : '';

            const isCompletedTask = event.extendedProps.task && event.extendedProps.task.isCompleted;
            const strikeClass = isCompletedTask ? 'line-through' : '';

            // Always create an icon element, even if empty
            let icon = null;
            if (event.extendedProps.serviceType) {
                icon =
                    event.extendedProps.serviceType === 'google' ? (
                        <GoogleWorkspaceIcon size={12} />
                    ) : (
                        <MicrosoftCalendarIcon size={12} />
                    );
            }

            if (isMobile) {
                const formatTime = (time) => {
                    const hour = time.getHours() % 12 || 12;
                    const minute = time.getMinutes();
                    const period = time.getHours() >= 12 ? 'PM' : 'AM';
                    return `${hour}${minute === 0 ? '' : `:${minute.toString().padStart(2, '0')}`} ${period}`;
                };

                return (
                    <div
                        title={event.title}
                        className={`p-2 rounded ${backgroundColor} ${strikeClass} mobile-event-content`}
                    >
                        <div className="flex gap-1 items-center text-sm text-gray-600">
                            <div style={{ width: '12px', height: '12px', display: 'flex', alignItems: 'center' }}>
                                {icon}
                            </div>
                            {formatTime(eventStart)} - {formatTime(eventEnd)}
                        </div>
                        <div className="mt-1 font-medium">{title}</div>
                    </div>
                );
            }

            // Return non-mobile rendering
            return (
                <div
                    title={event.title}
                    className={`p-0 m-0 rounded b-0 font-sfCompactDisplay ${backgroundColor} ${strikeClass}`}
                >
                    <div className="flex flex-col">
                        <div className="flex gap-1 items-center">
                            <div style={{ width: '12px', height: '12px', display: 'flex', alignItems: 'center' }}>
                                {icon}
                            </div>
                            {eventInfo.timeText} {title}
                        </div>
                    </div>
                </div>
            );
        },
        [isMobile]
    );

    const highlightOverlappingRow = useCallback((info) => {
        const now = new Date();
        const eventStart = new Date(info.event.start);
        const eventEnd = info.event.end ? new Date(info.event.end) : new Date(eventStart);

        // Remove the dot element
        const dotElement = info.el.querySelector('.fc-list-event-dot');
        if (dotElement) {
            dotElement.remove();
        }

        if (eventStart <= now && now <= eventEnd) {
            const rowEl = info.el.closest('tr');
            if (rowEl) {
                rowEl.classList.add('bg-green-50');
            }
        }
    }, []);

    const calendar = useMemo(
        () => (
            <FullCalendar
                key={key}
                ref={calendarRef}
                events={events}
                height="auto"
                contentHeight="auto"
                aspectRatio={1.8}
                eventMinHeight={25}
                eventDisplay="block"
                views={{
                    listMonth: {
                        eventMinWidth: '100%',
                        eventShortHeight: 'auto',
                    },
                }}
                slotEventOverlap={false}
                eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: 'short',
                }}
                eventDidMount={highlightOverlappingRow}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, rrulePlugin]}
                initialView="listMonth"
                eventContent={renderEventContent}
                scrollTime="08:00:00"
                weekends={true}
                handleWindowResize={true}
                editable={false}
                selectable={false}
                selectMirror={true}
                dayMaxEvents={true}
                expandRows={false}
                eventTextColor="white"
                eventBorderColor="red"
                nowIndicator={true}
                eventClick={handleEventClick}
                headerToolbar={false}
                titleFormat={{
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                }}
            />
        ),
        [events, key, highlightOverlappingRow, renderEventContent, handleEventClick]
    );

    if (isLoading) {
        return <InlineLoading />;
    }

    return (
        <div className={`w-full h-full text-black upcoming-events ${!isMobile ? 'bg-appBackground' : ''}`}>
            <div className="flex">
                <div className="flex-1 mb-2 ml-0 text-2xl font-bold text-blue-950">Upcoming Events</div>
            </div>

            {events.length === 0 ? (
                <div className="flex justify-center bg-appBackground">
                    There are no events. Have you&nbsp;
                    <a href="/settings" className="underline">
                        connected a calendar
                    </a>
                    ?
                </div>
            ) : (
                <div className="w-full bg-appBackground">{calendar}</div>
            )}
        </div>
    );
};

export default React.memo(UpcomingEvents);
