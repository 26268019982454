import React, { createContext, useContext, useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useCustomer } from '../providers/CustomerProvider';
import { useGoogleCalendarEvents } from '../components/tasks/hooks/useGoogleCalendarEvents';
import { useMicrosoftCalendarEvents } from '../components/tasks/hooks/useMicrosoftCalendarEvents';
import { useAtom } from 'jotai';
import { tokenAtom } from '../atoms/tokenAtoms';

const CalendarEventsContext = createContext();

export const useCalendarEvents = () => {
    const context = useContext(CalendarEventsContext);
    if (!context) {
        throw new Error('useCalendarEvents must be used within a CalendarEventsProvider');
    }
    return context;
};

export const CalendarEventsProvider = ({ children }) => {
    const { customer, isLoading: isCustomerLoading, refetchCustomer } = useCustomer();
    const [token] = useAtom(tokenAtom);
    const queryClient = useQueryClient();

    const { fetchGoogleCalendars, fetchGoogleEvents } = useGoogleCalendarEvents(customer);
    const { fetchMicrosoftCalendars, fetchMicrosoftEvents } = useMicrosoftCalendarEvents(customer);

    const refreshTokensAndUpdateCustomer = async () => {
        try {
            const response = await fetch(`${import.meta.env.VITE_PUBLIC_API_HOST}/api/calendar/refreshTokens`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to refresh tokens');
            }

            const updatedCustomer = await response.json();
            // Update the customer data in the CustomerProvider
            await refetchCustomer();
            return updatedCustomer;
        } catch (error) {
            console.error('Error refreshing tokens:', error);
            throw error;
        }
    };

    const fetchCalendarListForAllAccounts = useCallback(
        async (currentCustomer) => {
            console.log('Fetching calendar list with customer:', {
                hasCustomer: !!currentCustomer,
                hasAccounts: !!currentCustomer?.accounts,
                numAccounts: currentCustomer?.accounts?.length,
                accounts: currentCustomer?.accounts?.map(acc => ({
                    id: acc.id,
                    type: acc.type,
                    error: acc.error,
                    dirty: acc.dirty,
                    hasToken: !!acc.accessToken
                }))
            });

            if (!currentCustomer || !currentCustomer.accounts || currentCustomer.accounts.length === 0) {
                return [];
            }

            const newCalendarList = [];
            const accountsNeedingRefresh = [];

            for (const account of currentCustomer.accounts) {
                if (account.error || account.dirty) {
                    console.log(`Skipping account ${account.id} due to:`, {
                        error: account.error,
                        dirty: account.dirty
                    });
                    continue;
                }

                try {
                    if (account.type === 'google') {
                        console.log(`Fetching Google calendars for account ${account.id}`);
                        const accountCalendars = await fetchGoogleCalendars(account.accessToken);
                        if (accountCalendars?.items) {
                            console.log(`Found ${accountCalendars.items.length} Google calendars`);
                            accountCalendars.items.forEach((calendar) => {
                                calendar.account = account;
                                newCalendarList.push(calendar);
                            });
                        } else {
                            console.log('No Google calendars found or error occurred');
                        }
                    } else if (account.type === 'microsoft') {
                        console.log(`Fetching Microsoft calendars for account ${account.id}`);
                        const accountCalendars = await fetchMicrosoftCalendars(account.accessToken);
                        console.log(`Found ${accountCalendars.length} Microsoft calendars`);
                        accountCalendars.forEach((calendar) => {
                            calendar.account = account;
                            newCalendarList.push(calendar);
                        });
                    }
                } catch (error) {
                    if (error.response?.status === 401) {
                        console.log(`Account ${account.id} needs token refresh due to 401 error`);
                        accountsNeedingRefresh.push(account);
                        continue;
                    }
                    console.error(`Error fetching calendars for account ${account.id}:`, error);
                }
            }

            if (accountsNeedingRefresh.length > 0) {
                console.log('Some accounts need token refresh, refreshing tokens...', {
                    accounts: accountsNeedingRefresh.map(acc => acc.id)
                });
                const updatedCustomer = await refreshTokensAndUpdateCustomer();
                return fetchCalendarListForAllAccounts(updatedCustomer); // Retry with updated customer
            }

            console.log('Final calendar list:', {
                total: newCalendarList.length,
                byType: newCalendarList.reduce((acc, cal) => {
                    acc[cal.account.type] = (acc[cal.account.type] || 0) + 1;
                    return acc;
                }, {})
            });

            return newCalendarList;
        },
        [fetchGoogleCalendars, fetchMicrosoftCalendars]
    );

    const customerDeps = useMemo(
        () => ({
            accounts: customer?.accounts?.map((acc) => ({
                id: acc.id,
                type: acc.type,
                calendars: acc.calendars,
                accessToken: acc.accessToken,
                dirty: acc.dirty,
                error: acc.error,
            })),
            defaultTimeZone: customer?.defaultTimeZone,
        }),
        [customer]
    );

    const fetchCalendarEvents = useCallback(async () => {
        // Get the call stack
        // const stack = new Error().stack;
        // console.log('🔍 Calendar fetch triggered by:', stack);
        // console.log('🗓️ Fetching calendar events...');

        if (!customer || isCustomerLoading) {
            console.log('Customer not loaded or loading');
            return [];
        }

        try {
            // Add error boundary for calendar list fetching
            let calendarList;
            try {
                calendarList = await fetchCalendarListForAllAccounts(customer);
                console.log(`📅 Found ${calendarList.length} calendars`);
            } catch (error) {
                console.error('Error fetching calendar list:', error);
                return [];
            }

            // Add error boundaries for event fetching
            let googleEvents = [];
            let microsoftEvents = [];

            try {
                googleEvents = await fetchGoogleEvents(calendarList);
            } catch (error) {
                console.error('Error fetching Google events:', error);
            }

            try {
                microsoftEvents = await fetchMicrosoftEvents(calendarList);
            } catch (error) {
                console.error('Error fetching Microsoft events:', error);
            }

            const allEvents = [...googleEvents, ...microsoftEvents].map((event) => ({
                ...event,
                isMultiDay: new Date(event.start).toDateString() !== new Date(event.end).toDateString(),
                multiDayEventEndDate: event.end,
                end:
                    new Date(event.start).toDateString() !== new Date(event.end).toDateString()
                        ? event.start
                        : event.end,
            }));

            console.log(`✨ Processed ${allEvents.length} total events`);
            return allEvents;
        } catch (error) {
            console.error('Error in fetchCalendarEvents:', error);
            // Return empty array instead of throwing to prevent UI disruption
            return [];
        }
    }, [customerDeps, isCustomerLoading, fetchCalendarListForAllAccounts, fetchGoogleEvents, fetchMicrosoftEvents]);

    const {
        data: calendarEvents,
        isLoading,
        error,
    } = useQuery('calendarEvents', fetchCalendarEvents, {
        enabled: !!customer && !isCustomerLoading,
        retry: 2,
        staleTime: 5 * 60 * 1000,
        cacheTime: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchInterval: false,
        // Add metadata to track the source of refetch
        meta: {
            source: 'calendar',
        },
        onQueryUpdated: (action) => {
            if (action.type === 'invalidate') {
                const source = action.meta?.source;
                // Only refetch if invalidation came from a relevant source
                if (source === 'taskRescheduled' || source === 'calendarUpdate') {
                    console.log(`🔄 Refetching calendar due to ${source}`);
                    return true;
                }
                console.log('⏭️ Skipping calendar refetch for source:', source);
                return false;
            }
        },
    });

    const makeCalendarPrimary = useCallback(
        async (accountId) => {
            console.log('Making ' + accountId + ' primary account');
            try {
                const response = await fetch(
                    `${import.meta.env.VITE_PUBLIC_API_HOST}/api/calendar/makeAccountPrimary`,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ accountId: accountId }),
                    }
                );
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                await refetchCustomer();
                queryClient.invalidateQueries('calendarEvents');
            } catch (error) {
                console.error('Error making calendar primary:', error);
            }
        },
        [token, refetchCustomer, queryClient]
    );

    const deleteAccount = useCallback(
        async (email) => {
            console.log('Deleting account ' + email);
            try {
                const response = await fetch(`${import.meta.env.VITE_PUBLIC_API_HOST}/api/calendar/deleteAccount`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email }),
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                await refetchCustomer();
                queryClient.invalidateQueries('calendarEvents');
            } catch (error) {
                console.error('Error deleting account:', error);
            }
        },
        [token, refetchCustomer, queryClient]
    );

    const invalidateCalendarEvents = (source) => {
        queryClient.invalidateQueries({
            queryKey: 'calendarEvents',
            refetchType: 'active',
            meta: { source },
        });
    };

    const contextValue = useMemo(
        () => ({
            calendarEvents,
            isLoading,
            error,
            refetchCalendarEvents: () => {
                console.log('🔄 Manually refreshing calendar events...');
                return invalidateCalendarEvents('manual');
            },
            makeCalendarPrimary,
            deleteAccount,
        }),
        [calendarEvents, isLoading, error, queryClient, makeCalendarPrimary, deleteAccount]
    );

    return <CalendarEventsContext.Provider value={contextValue}>{children}</CalendarEventsContext.Provider>;
};
