import { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../atoms/tokenAtoms';
import { atomWithStorage } from 'jotai/utils';
import axios from 'axios';
import { useDeviceType } from '../../hooks/useDeviceType';

// Create atoms for global state
const chatMessagesAtom = atomWithStorage('chatMessages', []);
const isLoadingAtom = atomWithStorage('isLoadingChat', false);
const errorAtom = atomWithStorage('chatError', null);

export const useAIChat = () => {
    const [token] = useAtom(tokenAtom);
    const [aiResponse, setAIResponse] = useState('');
    const [chatMessages, setChatMessages] = useAtom(chatMessagesAtom);
    const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
    const [error, setError] = useAtom(errorAtom);
    const { isMobile } = useDeviceType();

    const fetchAIChat = async (message) => {
        setIsLoading(true);
        setError(null);
        try {
            console.log('Fetching AI response for message:', message);
            console.log('Sending chat history:', chatMessages);
            
            const requestBody = {
                currentMessage: message,
                chatHistory: chatMessages.map(msg => ({
                    text: msg.text,
                    isUser: msg.isUser,
                    timestamp: msg.timestamp // Send as ISO string directly
                })),
                isMobile: isMobile || false // Ensure we always send a boolean value
            };

            console.log('Request body:', JSON.stringify(requestBody, null, 2));
            
            const response = await axios.post(
                `${import.meta.env.VITE_PUBLIC_API_HOST}/api/ai/chat`,
                requestBody,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('AI response received:', response.data);
            setAIResponse(response.data);
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Failed to get AI response';
            console.error('Error details:', error.response?.data);
            setError(errorMessage);
            console.error('Error fetching AI chat:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (aiResponse) {
            console.log('Adding AI response to chat messages:', aiResponse);
            setChatMessages((prevMessages) => [
                ...prevMessages,
                { 
                    id: crypto.randomUUID(), 
                    text: aiResponse, 
                    timestamp: new Date().toISOString(), // Store as ISO string
                    isUser: false 
                },
            ]);
            setAIResponse('');
        }
    }, [aiResponse, setChatMessages]);

    const handleUserInput = async (value) => {
        console.log('Handling user input:', value);
        const newMessage = { 
            id: crypto.randomUUID(), 
            text: value, 
            timestamp: new Date().toISOString(), // Store as ISO string
            isUser: true 
        };
        setChatMessages((prevMessages) => [...prevMessages, newMessage]);
        await fetchAIChat(value);
    };

    const clearChat = () => {
        setChatMessages([]);
        setError(null);
    };

    return {
        chatMessages,
        handleUserInput,
        isLoading,
        error,
        clearChat,
    };
};