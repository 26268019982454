import React, { useEffect, useState, useMemo } from 'react';
import { useAtom } from 'jotai';
import { isFocusModeAtom } from '../atoms/isFocusModeAtom';
import Briefing from './home/Briefing';
import UpcomingEvents from './calendar/UpcomingEvents';
import { motion, AnimatePresence } from 'framer-motion';
import { useSettings } from '../atoms/SettingsAtom';
import { useIsLandscapeAndLargeScreen } from '../hooks/useIsLandscapeAndLargeScreen';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IconButton } from '@mui/material';
import { CenterFocusWeak } from '@mui/icons-material';
import { useCurrentPicture } from '../contexts/CurrentPictureContext';
import styled from '@emotion/styled';
import { Capacitor } from '@capacitor/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTaskModal } from '../contexts/TaskModalContext';

const FullScreenBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: ${(props) => (props.showBackground ? `url(${props.backgroundImage})` : 'none')};
    background-color: #f0f0f0;
    background-size: cover;
    background-position: center;
    transition: opacity 0.5s ease-in-out;
    opacity: ${(props) => (props.isLoaded ? 1 : 0)};
    z-index: -1;
`;

const padZero = (str, len = 2) => {
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
};

const invertColor = (hex) => {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        console.warn('Invalid HEX color provided. Using default color "#FFFFFF".');
        return '#FFFFFF';
    }
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
        g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
        b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    return '#' + padZero(r) + padZero(g) + padZero(b);
};

const Focus = () => {
    const [isFocusMode, setIsFocusMode] = useAtom(isFocusModeAtom);
    const { settings } = useSettings();
    const isLandscapeAndLargeScreen = useIsLandscapeAndLargeScreen();
    const { currentPicture, isLoading: isLoadingPicture } = useCurrentPicture();
    const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [isMobile] = useState(Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios');
    const { openTaskModal } = useTaskModal();

    const invertedColor = useMemo(() => {
        return currentPicture?.color !== undefined ? invertColor(currentPicture.color) : 'white';
    }, [currentPicture?.color]);

    useEffect(() => {
        if (currentPicture && !isMobile) {
            const img = new Image();
            img.src = currentPicture.pictureUrl;
            img.onload = () => {
                setIsBackgroundLoaded(true);
                setBackgroundImage(currentPicture.pictureUrl);
            };
        } else {
            setIsBackgroundLoaded(false);
            setBackgroundImage(null);
        }
    }, [currentPicture, isMobile]);

    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                setIsFocusMode(false);
            }
        };

        window.addEventListener('keydown', handleEscape);
        return () => window.removeEventListener('keydown', handleEscape);
    }, [setIsFocusMode]);

    // Add transition styles
    const variants = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
    };

    const handleBackgroundClick = (e) => {
        // Only handle clicks directly on the background, not on its children
        if (e.target === e.currentTarget) {
            console.log('🎯 Background clicked, exiting focus mode');
            setIsFocusMode(false);
        }
    };

    const handleAddTask = (e) => {
        e.preventDefault();
        openTaskModal(null);
    };

    if (!isFocusMode) return null;

    return (
        <DndProvider backend={HTML5Backend}>
            <AnimatePresence>
                <motion.div
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={variants}
                    transition={{ duration: 0.3 }}
                    className="fixed inset-0 z-[9999] bg-white"
                    onClick={handleBackgroundClick}
                >
                    <div>
                        <FullScreenBackground
                            showBackground={settings.showBackground}
                            backgroundImage={backgroundImage}
                            isLoaded={isBackgroundLoaded}
                        />

                        {/* Top bar with focus button only */}
                        <div className="absolute top-6 left-6">
                            <IconButton
                                onClick={() => setIsFocusMode(false)}
                                className="shadow-lg bg-white/75 hover:bg-white"
                                size="large"
                                style={{
                                    padding: '16px',
                                    transform: 'scale(1.2)',
                                }}
                            >
                                <CenterFocusWeak style={{ fontSize: '28px' }} />
                            </IconButton>
                        </div>

                        {/* Centered Add Task button */}
                        <div className="flex justify-center mt-6 mb-4">
                            <button
                                onClick={handleAddTask}
                                className="flex items-center gap-2 px-6 py-2.5 text-white bg-green-500 rounded-full 
                                         shadow-md transition-all duration-300 hover:bg-green-600 hover:shadow-lg 
                                         active:bg-green-700 active:shadow-md focus:outline-none focus:ring-2 
                                         focus:ring-green-500 focus:ring-opacity-50"
                            >
                                <FontAwesomeIcon icon={faPlus} className="text-lg" />
                                <span className="font-medium">Add New Task / Habit</span>
                            </button>
                        </div>

                        <div
                            className={`flex ${isLandscapeAndLargeScreen ? 'justify-between px-16 py-8' : 'flex-col p-4'}`}
                        >
                            <div className={isLandscapeAndLargeScreen ? 'w-[45%]' : 'mb-4 w-full'}>
                                <div className="p-0 pl-3 rounded-lg bg-appBackground border-1 border-darkgray custom-shadow">
                                    <Briefing isPlanner={false} />
                                </div>
                            </div>

                            <div className={isLandscapeAndLargeScreen ? 'w-[35%]' : 'w-full'}>
                                <div className="p-1 rounded-lg border-2 bg-appBackground border-darkgray custom-shadow">
                                    <UpcomingEvents />
                                </div>
                            </div>
                        </div>

                        {settings.showBackground && backgroundImage && isBackgroundLoaded && (
                            <div
                                className="fixed right-0 bottom-0 mr-16 mb-16 text-xl transform -translate-x-1/2 -translate-y-1/2"
                                style={{ color: invertedColor }}
                            >
                                <a
                                    className="underline"
                                    href={backgroundImage}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Photo
                                </a>
                                <span className="ml-2">by</span>
                                <a
                                    href={`${currentPicture.photographerProfileLink}?utm_source=Mind Your Now&utm_medium=referral`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="ml-2 underline hover:text-gray-600"
                                >
                                    {currentPicture.photographer}
                                </a>
                                <span className="ml-2">on</span>
                                <a
                                    href="https://unsplash.com/?utm_source=Mind Your Now&utm_medium=referral"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="ml-2 underline hover:text-gray-600"
                                >
                                    Unsplash
                                </a>
                            </div>
                        )}
                    </div>
                </motion.div>
            </AnimatePresence>
        </DndProvider>
    );
};

export default Focus;
