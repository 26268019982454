import React, { useState } from 'react';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    Image,
    BrokenImage,
    Autorenew,
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    Cancel as CancelIcon,
    CenterFocusStrong,
} from '@mui/icons-material';
import { IconButton, Paper, CircularProgress } from '@mui/material';
import * as Tooltip from '@radix-ui/react-tooltip';
import { useSettings } from './atoms/SettingsAtom';
import { usePlanning } from './hooks/usePlanning';
import { useAtom } from 'jotai';
import { isFocusModeAtom } from './atoms/isFocusModeAtom';
import { isReschedulingAtom } from './atoms/isReschedulingAtom';
import { useCustomer } from './providers/CustomerProvider';
import { useAIChat } from './components/utils/useAIChat';
import AIChatModal from './components/ai/AIChatModal';
import { useDeviceType } from './hooks/useDeviceType';
import { Sparkles } from 'lucide-react';

const ActionButton = ({ id, tooltip, shortcut, onClick, disabled, children }) => (
    <Tooltip.Provider>
        <Tooltip.Root>
            <Tooltip.Trigger asChild>
                <span>
                    <IconButton 
                        data-tooltip-id={id}
                        onClick={onClick} 
                        disabled={disabled}
                        className="text-gray-600 hover:text-gray-800 disabled:text-gray-400"
                    >
                        {children}
                    </IconButton>
                </span>
            </Tooltip.Trigger>
            <Tooltip.Portal>
                <Tooltip.Content
                    className="bg-gray-800 text-white px-3 py-1.5 rounded text-sm z-[9999]"
                    sideOffset={5}
                >
                    {tooltip}
                    {shortcut && (
                        <span className="ml-2 px-1.5 py-0.5 bg-gray-700 rounded text-xs">
                            {shortcut}
                        </span>
                    )}
                    <Tooltip.Arrow className="fill-gray-800" />
                </Tooltip.Content>
            </Tooltip.Portal>
        </Tooltip.Root>
    </Tooltip.Provider>
);

export const ActionBar = () => {
    const { settings, toggleSetting } = useSettings();
    const { instantPlan, unScheduleAll } = usePlanning();
    const [isRescheduling] = useAtom(isReschedulingAtom);
    const [isFocusMode, setIsFocusMode] = useAtom(isFocusModeAtom);
    const { customer } = useCustomer();
    const { isMobile } = useDeviceType();
    const { chatMessages, handleUserInput } = useAIChat();
    const [isChatModalOpen, setIsChatModalOpen] = useState(false);

    const isPremiumUser = customer?.stripeStatus === 'active' || customer?.id === 1;

    const handleOpenChatModal = () => {
        setIsChatModalOpen(true);
    };

    const handleCloseChatModal = () => {
        setIsChatModalOpen(false);
    };

    const actionButtons = [
        {
            id: "toggle-background",
            tooltip: 'Toggle Background',
            shortcut: "T + B",
            onClick: () => toggleSetting('showBackground'),
            icon: settings.showBackground ? (
                <Image style={{ fontSize: '2rem' }} />
            ) : (
                <BrokenImage style={{ fontSize: '2rem' }} />
            ),
        },
        {
            id: "toggle-future",
            tooltip: 'Toggle Future Tasks',
            shortcut: "T + F",
            onClick: () => toggleSetting('showFuture'),
            icon: settings.showFuture ? <VisibilityIcon /> : <VisibilityOffIcon />,
        },
        {
            id: "toggle-completed",
            tooltip: 'Toggle Completed Tasks',
            shortcut: "T + C",
            onClick: () => toggleSetting('showCompleted'),
            icon: settings.showCompleted ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />,
        },
        {
            id: "toggle-focus",
            tooltip: 'Toggle Focus Mode',
            shortcut: "F + M",
            onClick: () => setIsFocusMode(!isFocusMode),
            icon: <CenterFocusStrong className={isFocusMode ? 'text-green-500' : ''} />,
            className: isFocusMode ? 'bg-green-100' : '',
        },
        {
            id: "generate-plan",
            tooltip: 'Fetch new plan',
            shortcut: "G + P",
            onClick: instantPlan,
            icon: isRescheduling ? (
                <CircularProgress size={27} style={{ color: 'green' }} />
            ) : (
                <Autorenew style={{ fontSize: '2rem' }} />
            ),
        },
        {
            id: "unschedule-all",
            tooltip: 'Unschedule all tasks',
            shortcut: "U + A",
            onClick: unScheduleAll,
            icon: <CancelIcon style={{ fontSize: '2rem' }} />,
        },
        // Add AI Chat button only for premium users and non-mobile
        ...(isPremiumUser && !isMobile
            ? [
                  {
                      id: "chat-ai",
                      tooltip: 'Chat with AI',
                      shortcut: "C + A",
                      onClick: handleOpenChatModal,
                      icon: <Sparkles size={24} />,
                  },
              ]
            : []),
    ];

    if (isMobile) {
        return null;
    }

    return (
        <>
            <Paper elevation={3} className="flex items-center p-2 bg-white rounded-lg">
                <div className="flex items-center space-x-4">
                    {actionButtons.map((button, index) => (
                        <ActionButton
                            key={button.id}
                            id={button.id}
                            tooltip={button.tooltip}
                            shortcut={button.shortcut}
                            onClick={button.onClick}
                            className={button.className}
                        >
                            {button.icon}
                        </ActionButton>
                    ))}
                </div>
            </Paper>
            {isPremiumUser && !isMobile && (
                <AIChatModal isOpen={isChatModalOpen} onClose={handleCloseChatModal} />
            )}
        </>
    );
};
