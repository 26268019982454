import React, { useMemo, useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { useAtom } from 'jotai';
import { tokenAtom, isTokenExpiredAtom } from '../../atoms/tokenAtoms';

const SCHEDULES_URL = `${import.meta.env.VITE_PUBLIC_API_HOST}/api/schedules`;

// Utility functions moved outside the hook for potential reuse
const formatAMPM = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

const getFirstSundayOfYear = () => {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), 0, 1);
    const dayOfWeek = firstDay.getDay();
    const firstSunday = new Date(firstDay);
    firstSunday.setDate(firstDay.getDate() + (7 - dayOfWeek));
    return firstSunday;
};

export const useSchedules = () => {
    const [token] = useAtom(tokenAtom);
    const [isTokenExpired] = useAtom(isTokenExpiredAtom);
    const queryClient = useQueryClient();

    const getAuthHeaders = useCallback(
        () => ({
            Authorization: `Bearer ${token}`,
        }),
        [token]
    );

    const fetchSchedules = useCallback(async () => {
        if (isTokenExpired) {
            throw new Error('Token is expired');
        }
        const response = await axios.get(SCHEDULES_URL, {
            headers: getAuthHeaders(),
            mode: 'no-cors',
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Failed to fetch schedules: ' + response.statusText);
        }
    }, [getAuthHeaders, isTokenExpired]);

    const scheduleBlocksToEvents = useCallback((scheduleBlocks) => {
        const firstSunday = getFirstSundayOfYear();
        return scheduleBlocks.map((block) => {
            const startDate = new Date(firstSunday);
            startDate.setDate(firstSunday.getDate() + block.dayOfWeek);
            const [startHours, startMinutes] = block.startTime.split(':');
            startDate.setHours(parseInt(startHours, 10), parseInt(startMinutes, 10));

            const endDate = new Date(startDate);
            const [endHours, endMinutes] = block.endTime.split(':');
            endDate.setHours(parseInt(endHours, 10), parseInt(endMinutes, 10));

            return {
                id: block.id,
                start: startDate,
                end: endDate,
            };
        });
    }, []);

    const eventsToScheduleBlocks = useCallback((events) => {
        if (!events || !Array.isArray(events)) {
            return [];
        }
        return events
            .map((event) => {
                if (!event.start || !event.end) {
                    return null;
                }
                const start = formatAMPM(new Date(event.start));
                const end = formatAMPM(new Date(event.end));
                const dayOfWeek = new Date(event.end).getDay();
                return { startTime: start, endTime: end, dayOfWeek };
            })
            .filter(Boolean);
    }, []);

    const {
        data: rawSchedules,
        isLoading,
        error,
        refetch,
    } = useQuery('schedules', fetchSchedules, {
        enabled: !!token && !isTokenExpired,
        retry: 5,
        retryDelay: (attempt) => Math.min(attempt * 1000, 30000),
        staleTime: 5 * 60 * 1000, // 5 minutes
    });

    const schedules = useMemo(() => {
        if (!rawSchedules) return [];
        return rawSchedules.map((schedule) => ({
            ...schedule,
            events: scheduleBlocksToEvents(schedule.scheduleBlocks),
        }));
    }, [rawSchedules, scheduleBlocksToEvents]);

    const createSchedule = useCallback(
        async (newSchedule) => {
            console.log('Creating schedule with events:', newSchedule.events);
            // Convert events to schedule blocks with 24-hour format
            const scheduleBlocks = newSchedule.events.map(event => {
                const start = new Date(event.start);
                const end = new Date(event.end);
                return {
                    startTime: `${start.getHours().toString().padStart(2, '0')}:${start.getMinutes().toString().padStart(2, '0')}:00`,
                    endTime: `${end.getHours().toString().padStart(2, '0')}:${end.getMinutes().toString().padStart(2, '0')}:00`,
                    dayOfWeek: start.getDay()
                };
            });
            
            console.log('Converted to schedule blocks:', scheduleBlocks);
            const scheduleToSend = {
                id: newSchedule.id,
                name: newSchedule.name,
                timeZone: newSchedule.timeZone,
                scheduleBlocks
            };
            
            console.log('Sending schedule:', scheduleToSend);
            const response = await axios.post(SCHEDULES_URL, scheduleToSend, { headers: getAuthHeaders() });
            queryClient.invalidateQueries('schedules');
            return response.data;
        },
        [getAuthHeaders, queryClient]
    );

    const updateSchedule = useCallback(
        async (updatedSchedule) => {
            let scheduleToSend = { ...updatedSchedule };
            if (updatedSchedule.events && updatedSchedule.events.length > 0) {
                scheduleToSend.scheduleBlocks = eventsToScheduleBlocks(updatedSchedule.events);
            } else if (!updatedSchedule.scheduleBlocks || updatedSchedule.scheduleBlocks.length === 0) {
                scheduleToSend.scheduleBlocks = [];
            }
            delete scheduleToSend.events;

            const response = await axios.put(`${SCHEDULES_URL}/${updatedSchedule.id}`, scheduleToSend, {
                headers: getAuthHeaders(),
            });
            queryClient.invalidateQueries('schedules');
            return response.data;
        },
        [eventsToScheduleBlocks, getAuthHeaders, queryClient]
    );

    const deleteSchedule = useCallback(
        async (id) => {
            await axios.delete(`${SCHEDULES_URL}/${id}`, { headers: getAuthHeaders() });
            queryClient.invalidateQueries(['schedule', id]);
            queryClient.invalidateQueries('schedules');
            return id;
        },
        [getAuthHeaders, queryClient]
    );

    return {
        schedules,
        isLoading,
        error,
        refetch,
        createSchedule,
        updateSchedule,
        deleteSchedule,
        formatAMPM,
    };
};

export default useSchedules;
