import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';

export function CommandBarButton({ setOpen }) {
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const shortcutText = isMac ? '⌘K' : 'Ctrl+K';

  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <button
            onClick={() => setOpen(true)}
            className="flex flex-col items-center px-4 py-2 text-gray-600 bg-white/80 rounded-lg 
                     shadow-sm hover:bg-white/90 transition-all duration-200 border border-gray-200 min-w-[100px]"
          >
            <span className="font-medium whitespace-nowrap">Command Bar</span>
            <span className="text-xs text-gray-500 mt-0.5">{shortcutText}</span>
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="bg-gray-800 text-white px-3 py-1.5 rounded text-sm z-[9999]"
            sideOffset={5}
          >
            Press {shortcutText} to open command menu
            <Tooltip.Arrow className="fill-gray-800" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
