import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark as CloseTaskIcon } from '@fortawesome/free-solid-svg-icons';
import { Avatar, Box, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { differenceInHours, differenceInDays, differenceInMonths, parseISO } from 'date-fns';

import criticalImage from '/images/noun-exclamation-green.svg';
import overTheHorizonImage from '/images/noun-horizon-orange.svg';
import opportunityNowImage from '/images/noun-opportunity-blue.svg';
import parkingLotImage from '/images/noun-parking-lot-red.svg';
import nounTomorrow from '/images/noun-tomorrow-purple.svg';

import { useTaskSchedule } from './hooks/useTaskSchedule';
import { useTaskActions } from './hooks/useTaskActions';

const theme = {
    palette: {
        NEEDS_PLANNING: { main: '#9333EA', contrastText: '#fff' },
        TOMORROW: { main: '#8B6BBA', contrastText: '#fff' },
        CRITICAL: { main: '#0C803D', contrastText: '#fff' },
        OPPORTUNITY_NOW: { main: '#107CC4', contrastText: '#fff' },
        OVER_THE_HORIZON: { main: '#F9913B', contrastText: '#fff' },
        PARKING_LOT: { main: '#F04F23', contrastText: '#fff' },
    },
};

const formatReviewTime = (date) => {
    if (!date) return '';
    const now = new Date();
    const parsedDate = parseISO(date);
    const hours = differenceInHours(parsedDate, now);
    const days = differenceInDays(parsedDate, now);
    const months = differenceInMonths(parsedDate, now);

    if (hours < 24) {
        return `IN ${hours} HOUR${hours !== 1 ? 'S' : ''}`;
    } else if (days < 30) {
        return `IN ${days} DAY${days !== 1 ? 'S' : ''}`;
    } else {
        return `IN A MONTH`;
    }
};

const getReviewDate = (taskSchedule, priority) => {
    switch (priority) {
        case 'OPPORTUNITY_NOW':
            return taskSchedule.opportunityNowDate;
        case 'OVER_THE_HORIZON':
            return taskSchedule.overTheHorizonDate;
        case 'PARKING_LOT':
            return taskSchedule.parkingLotDate;
        default:
            return null;
    }
};

export const PriorityPanel = ({ task, notifyClose }) => {
    const { taskSchedule, refetch } = useTaskSchedule();
    const { saveTask } = useTaskActions(task);

    const handleSelection = (event, priority, shouldClose) => {
        event.stopPropagation();
        const updatedTask = { ...task, priority };
        saveTask(updatedTask);
        if (shouldClose) {
            notifyClose();
        }
        refetch();
    };

    const priorityOptions = [
        { label: 'Tomorrow', priority: 'TOMORROW', image: nounTomorrow, description: 'Move task to tomorrow' },
        { label: 'Critical', priority: 'CRITICAL', image: criticalImage, description: 'Tasks you will do today' },
        {
            label: 'Opportunity Now',
            priority: 'OPPORTUNITY_NOW',
            image: opportunityNowImage,
            description: 'Tasks you may start soon',
        },
        {
            label: 'Over The Horizon',
            priority: 'OVER_THE_HORIZON',
            image: overTheHorizonImage,
            description: 'Review in the near future',
        },
        { label: 'Parking Lot', priority: 'PARKING_LOT', image: parkingLotImage, description: 'Longer-term ideas' },
    ];

    if (!taskSchedule) {
        console.log('taskSchedule is null or undefined');
        return null; // or a loading indicator
    }

    return (
        <Box sx={{ border: 4, borderRadius: 5, backgroundColor: 'white', borderColor: 'white', p: 2 }}>
            <div className="flex p-2 mb-1">
                <FontAwesomeIcon
                    className="pt-1 mr-2 text-3xl cursor-pointer hover:text-white hover:bg-mynlightblue-400 hover:opacity-100"
                    color="gray"
                    icon={CloseTaskIcon}
                    onClick={notifyClose}
                    data-bs-toggle="tooltip"
                    title="Cancel Edits"
                />
                <div
                    style={{ color: theme.palette[task.priority]?.main || 'gray' }}
                    className="flex pt-1 mr-4 text-2xl font-bold"
                >
                    Change priority?
                </div>
            </div>
            <Typography variant="h6" sx={{ mb: 2 }}>
                {task.title}
            </Typography>
            {priorityOptions.map(({ label, priority, image, description }) => {
                const priorityColor = theme.palette[priority].main;
                const reviewDate = getReviewDate(taskSchedule, priority);
                const showReviewInfo = ['OVER_THE_HORIZON', 'OPPORTUNITY_NOW', 'PARKING_LOT'].includes(priority);

                return (
                    <Box
                        key={priority}
                        onClick={(e) => handleSelection(e, priority, true)}
                        sx={{
                            mb: 1,
                            border: 2,
                            borderColor: priorityColor,
                            borderRadius: 2,
                            '&:hover': { backgroundColor: `${priorityColor}10` },
                            padding: '8px 12px', // Reduced padding to minimize extra space
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar alt={`${label} Image`} src={image} sx={{ width: 40, height: 40, marginRight: 2 }} />
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: priorityColor }}>
                                    {label}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {description}
                                </Typography>
                            </Box>
                        </Box>
                        {showReviewInfo && reviewDate && (
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                                <button
                                    style={{
                                        backgroundColor: priorityColor,
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '4px',
                                        padding: '4px 8px',
                                        fontSize: '0.75rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={(e) => handleSelection(e, priority, true)}
                                >
                                    <span style={{ marginRight: '4px' }}>REVIEW {formatReviewTime(reviewDate)}</span>
                                    <SendIcon style={{ fontSize: '0.875rem' }} />
                                </button>
                            </Box>
                        )}
                    </Box>
                );
            })}
        </Box>
    );
};

export default React.memo(PriorityPanel);
