import React from 'react';
import { Dialog, IconButton, Box } from '@mui/material';
import { X } from 'lucide-react';
import AIChat from './AIChat';
import { useAtom } from 'jotai';
import { isAIModalOpenAtom } from '../../atoms/isAIModalOpenAtom';
import { useEffect } from 'react';

const AIChatModal = ({ isOpen, onClose }) => {
    const [, setIsAIModalOpen] = useAtom(isAIModalOpenAtom);

    useEffect(() => {
        setIsAIModalOpen(isOpen);
        return () => setIsAIModalOpen(false);
    }, [isOpen, setIsAIModalOpen]);

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    m: 2,
                    height: 'calc(100vh - 32px)',
                    maxHeight: 'calc(100vh - 32px)',
                    borderRadius: 2,
                    position: 'relative',
                    overflow: 'hidden'
                }
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    left: { xs: 4, sm: 8 },
                    top: { xs: 4, sm: 8 },
                    zIndex: 1200,
                }}
            >
                <IconButton
                    onClick={onClose}
                    size="small"
                    sx={{
                        bgcolor: 'background.paper',
                        '&:hover': {
                            bgcolor: 'action.hover',
                        },
                        boxShadow: 1,
                    }}
                    aria-label="Close modal"
                >
                    <X size={20} />
                </IconButton>
            </Box>
            <AIChat />
        </Dialog>
    );
};

export default AIChatModal;
