import { Capacitor } from '@capacitor/core';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import { isReschedulingAtom } from './atoms/isReschedulingAtom';
import { useCustomer } from './providers/CustomerProvider';
import { ActionBar } from './ActionBar';
import { useTaskModal } from './contexts/TaskModalContext';
import SubscriptionBadge from './SubscriptionBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Tooltip from '@radix-ui/react-tooltip';
import { CommandBarButton } from './components/CommandMenu/CommandBarButton';
import { CommandMenu } from './components/CommandMenu/CommandMenu';
import {
    faHome,
    faCalendarAlt,
    faChartBar,
    faChartLine,
    faCalendar,
    faRecycle,
    faCog,
    faCalendarWeek,
    faRepeat,
    faListCheck,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';

const TopNavBar = () => {
    const isMobile = Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios';
    const [isRescheduling] = useAtom(isReschedulingAtom);
    const location = useLocation();
    const { customer, isLoading: isLoadingCustomer } = useCustomer();
    const { openTaskModal } = useTaskModal();
    const [isOpen, setOpen] = useState(false);

    // Return null if no customer or loading
    if (!customer || isLoadingCustomer) {
        return null;
    }

    // Return null for mobile devices
    if (isMobile) {
        return null;
    }

    const subscriptionStatus = customer.stripeStatus === 'active' ? 'Premium User' : 'Free Tier';

    const getPageTitleConfig = () => {
        const path = location.pathname.split('/')[1] || 'home';
        const configs = {
            home: { title: 'Home', icon: faHome, color: 'text-blue-800' },
            organizer: { title: 'Organizer', icon: faCalendar, color: 'text-blue-800' },
            stats: { title: 'Stats', icon: faChartLine, color: 'text-blue-800' },
            calendar: { title: 'Calendar', icon: faCalendar, color: 'text-blue-800' },
            habits: { title: 'Habits', icon: faRecycle, color: 'text-blue-800' },
            settings: { title: 'Settings', icon: faCog, color: 'text-blue-800' },
            priorities: { title: 'Priorities', icon: faChartBar, color: 'text-blue-800' },
            'upcoming-events': { title: 'Upcoming Events', icon: faCalendarWeek, color: 'text-blue-800' },
            planner: { title: 'Planner', icon: faListCheck, color: 'text-blue-800' },
        };
        return configs[path.toLowerCase()] || configs.home;
    };

    return (
        <>
            <div className="flex sticky top-0 z-50 justify-between items-center p-4 shadow-sm bg-appBackground">
                {/* Left section */}
                <div className="flex-1">
                    <h1 className="flex items-center text-4xl font-bold">
                        <FontAwesomeIcon icon={getPageTitleConfig().icon} className="mr-4 text-blue-500" />
                        <span className={getPageTitleConfig().color}>{getPageTitleConfig().title}</span>
                    </h1>
                </div>

                {/* Center section */}
                <div className="flex flex-[2] items-center justify-center">
                    <Tooltip.Provider>
                        <Tooltip.Root>
                            <Tooltip.Trigger asChild>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openTaskModal(null);
                                    }}
                                    className="flex items-center gap-2 px-6 py-2.5 text-white bg-green-500 rounded-full 
                                             shadow-md transition-all duration-300 hover:bg-green-600 hover:shadow-lg 
                                             active:bg-green-700 active:shadow-md focus:outline-none focus:ring-2 
                                             focus:ring-green-500 focus:ring-opacity-50"
                                >
                                    <FontAwesomeIcon icon={faPlus} className="text-lg" />
                                    <span className="font-medium">Add New Task / Habit</span>
                                </button>
                            </Tooltip.Trigger>
                            <Tooltip.Portal>
                                <Tooltip.Content
                                    className="bg-gray-800 text-white px-3 py-1.5 rounded text-sm z-[9999]"
                                    sideOffset={5}
                                >
                                    Add a new task or habit
                                    <Tooltip.Arrow className="fill-gray-800" />
                                </Tooltip.Content>
                            </Tooltip.Portal>
                        </Tooltip.Root>
                    </Tooltip.Provider>
                </div>

                {/* Right section */}
                <div className="flex-1 flex justify-end items-center gap-4">
                    <SubscriptionBadge status={subscriptionStatus} />
                    <CommandBarButton setOpen={setOpen} />
                    <ActionBar />
                </div>
            </div>
            <CommandMenu open={isOpen} setOpen={setOpen} />
        </>
    );
};

export default TopNavBar;
